// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Divider,
  TableRow,
  IconButton,
  Modal,
  Fade,
  Backdrop,
  Tooltip,
  MenuItem,
  TableContainer,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CSSProperties } from "@material-ui/styles";
import { Menu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import { withTranslation } from "react-i18next";
import ListIcon from "@material-ui/icons/List";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import { ComplexMapComponent } from "../../../../components/src/MapComponent/index";
import RoomIcon from "@material-ui/icons/Room";
import ComplexListingTableController, { Props } from "./ComplexListingTableController.web";
import { RealEstateFilter } from "../../../../components/src/RealEstateFilter/index";
import { TabsComponent } from "../../../../components/src/TabsComponent";
import { Link } from "react-router-dom";
import { complexIcon, buildingIcon } from "./assets";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import { LanguageAttributeHandler } from "../../../../components/src/UtilFunction.web";
import MapDialog from "../../../../components/src/MuiDialog/MapDialog.web";
import Loader from "../../../../components/src/Loader.web";

const registerOptions = [
  {
    icon: complexIcon,
    title: "Complex",
    link: "ComplexMainBlock",
  },
  {
    icon: buildingIcon,
    title: "Individual Building",
    link: "BuildingMainBlock",
  },
];

class ComplexListingTable extends ComplexListingTableController {
  constructor(props: Props) {
    super(props);
  }

  handleCooComplexListing = (t: any, language: any) => (
    <>
      <TableHead>
        <TableRow>
          {this.state.accountType === "coo" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Company Name")}
            </TableCell>
          )}
          {this.state.accountType === "account_manager" && (
            <TableCell className="bold-text complex" align={languageCondition(language, "right", "left")}>
              {t("Complex Id")}
            </TableCell>
          )}
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Complex Name")}
          </TableCell>
          {this.state.accountType !== "account_manager" && (
            <TableCell className="complex-tab bold-text" align={languageCondition(language, "right", "left")}>
              {t("Account Manager")}
            </TableCell>
          )}
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Total Buildings")}
          </TableCell>
          <TableCell className="complex-tab bold-text" align={languageCondition(language, "right", "left")}>
            {t("Total Units")}
          </TableCell>
          {this.state.accountType !== "coo" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Subscription Ends in")}
            </TableCell>
          )}
          {this.state.accountType !== "account_manager" && (
            <TableCell className="complex-table-tab bold-text" align={languageCondition(language, "right", "left")}>
              {t("Subscription Type")}
            </TableCell>
          )}
          {this.state.accountType !== "account_manager" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Paying Month")}
            </TableCell>
          )}
          {this.state.accountType === "account_manager" && (
            <TableCell className="bold-text complex-item-tab" align={languageCondition(language, "right", "left")}>
              {t("Country")}
            </TableCell>
          )}
          {this.state.accountType === "account_manager" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("City")}
            </TableCell>
          )}
          {this.state.accountType === "account_manager" && (
            <TableCell className="tab-item bold-text" align={languageCondition(language, "right", "left")}>
              {t("Status")}
            </TableCell>
          )}
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {this.state.complexList.length === 0 && (
          <TableRow>
            <TableCell colSpan={5}>{t("No data found")}</TableCell>
          </TableRow>
        )}
        {this.state.complexList.map((row: any, i: number) => {
          return (
            <TableRow key={i} style={{ borderBottom: "1px solid grey" }}>
              {this.state.accountType === "coo" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  <div>
                    <Tooltip title={row.attributes.company_name?.company_name}>
                      <div>{this.nerfNames(row.attributes.company_name?.company_name) || "-"}</div>
                    </Tooltip>
                  </div>
                </TableCell>
              )}
              {this.state.accountType === "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>{row.id}</TableCell>
              )}
              <TableCell align={languageCondition(language, "right", "left")}>
                <Tooltip title={row.attributes?.name}>
                  <div>{this.nerfNames(row.attributes?.name)}</div>
                </Tooltip>
              </TableCell>
              {this.state.accountType !== "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  {row.attributes.account_manager || "-"}
                </TableCell>
              )}
              <TableCell align={languageCondition(language, "right", "left")}>
                {row.attributes.total_buildings_custum}
              </TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>
                {row.attributes.total_units_custum}
              </TableCell>
              {this.state.accountType !== "coo" && (
                <TableCell align={languageCondition(language, "right", "left")}>{12}</TableCell>
              )}
              {this.state.accountType !== "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  {row.attributes.subscription_type || "-"}
                </TableCell>
              )}
              {this.state.accountType !== "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  {row.attributes.paying_month || "-"}
                </TableCell>
              )}
              {this.state.accountType === "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  {this.nerfNames(row.attributes.country)}
                </TableCell>
              )}
              {this.state.accountType === "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  {this.nerfNames(row.attributes.city)}
                </TableCell>
              )}
              {this.state.accountType === "account_manager" && (
                <TableCell>
                  <span style={webStyle[row.attributes.status]} className="bold-text">
                    {row.attributes.status || "-"}
                  </span>
                </TableCell>
              )}
              <TableCell className="view-page-menu textid" align={languageCondition(language, "right", "left")}>
                <Menu
                  menuButton={
                    <IconButton>
                      <MoreVertIcon />
                    </IconButton>
                  }
                  className="textid"
                  style={webStyle.btnMenu}
                >
                  <MenuItem
                    className="map-menu"
                    onClick={() =>
                      this.setState({ lat: row.attributes.lat, long: row.attributes.long }, () => this.handleMapModal())
                    }
                  >
                    {t("View location on map")}
                  </MenuItem>
                  <MenuItem className="sm-cancel-menu textid">
                    <Link to={`/CompanyEmployee/ComplexDetails/${row.id}`}>{t("Go to complex page")}</Link>
                  </MenuItem>
                  {this.state.accountType === "account_manager" && row.attributes.status === "rejected" && (
                    <MenuItem onClick={this.handleRejectionModal} className="sm-reject-menu textid">
                      {t("View Rejection Reason")}
                    </MenuItem>
                  )}
                </Menu>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );

  handleCooBuildingListing = (t: any, language: any) => (
    <>
      <TableHead>
        <TableRow style={{ whiteSpace: "nowrap" }}>
          {this.state.accountType === "account_manager" && (
            <TableCell className="building-tab bold-text" align={languageCondition(language, "right", "left")}>
              {t("Building Id")}
            </TableCell>
          )}
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Building Name")}
          </TableCell>
          {this.state.accountType !== "account_manager" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Account Manager")}
            </TableCell>
          )}
          <TableCell className="building-item-tab bold-text" align={languageCondition(language, "right", "left")}>
            {t("Chairman Name")}
          </TableCell>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Total Units")}
          </TableCell>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Subscription Ends in")}
          </TableCell>
          {this.state.accountType !== "account_manager" && (
            <TableCell className="item-tab bold-text" align={languageCondition(language, "right", "left")}>
              {t("Subscription Type")}
            </TableCell>
          )}
          {this.state.accountType !== "account_manager" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Paying Month")}
            </TableCell>
          )}
          {this.state.accountType === "account_manager" && (
            <TableCell className="bold-text  item-tab" align={languageCondition(language, "right", "left")}>
              {t("Country")}
            </TableCell>
          )}
          {this.state.accountType === "account_manager" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("City")}
            </TableCell>
          )}
          {this.state.accountType === "account_manager" && (
            <TableCell className="bold-text building-tab-item" align={languageCondition(language, "right", "left")}>
              {t("Status")}
            </TableCell>
          )}
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {this.state.buildingList.length === 0 && (
          <TableRow>
            <TableCell colSpan={5}>{t("No data found")}</TableCell>
          </TableRow>
        )}
        {this.state.buildingList?.map((row: any, i: number) => {
          return (
            <TableRow key={i}>
              {this.state.accountType === "account_manager" && (
                <TableCell className="building-item" align={languageCondition(language, "right", "left")}>
                  {row.id}
                </TableCell>
              )}
              <TableCell align={languageCondition(language, "right", "left")}>
                <Tooltip className="building-tooltip" title={row.attributes?.name}>
                  <div>{this.nerfNames(row.attributes?.name)}</div>
                </Tooltip>
              </TableCell>
              {this.state.accountType !== "account_manager" && (
                <TableCell className="building-tab" align={languageCondition(language, "right", "left")}>
                  {row.attributes.account_manager || "-"}
                </TableCell>
              )}
              <TableCell className="building-chairman" align={languageCondition(language, "right", "left")}>
                {row.attributes.chairman_nominations || "-"}
              </TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>
                {row.attributes.total_units?.length}
              </TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>
                {row.attributes.subscription_end_in || "-"}
              </TableCell>
              {this.state.accountType !== "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  {row.attributes.subscription_type || "-"}
                </TableCell>
              )}
              {this.state.accountType !== "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  {row.attributes?.paying_month || "-"}
                </TableCell>
              )}
              {this.state.accountType === "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  <Tooltip title={row.attributes?.country}>
                    <div>{this.nerfNames(row.attributes?.country)}</div>
                  </Tooltip>
                </TableCell>
              )}
              {this.state.accountType === "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  <Tooltip title={row.attributes?.city}>
                    <div>{this.nerfNames(row.attributes?.city)}</div>
                  </Tooltip>
                </TableCell>
              )}
              {this.state.accountType === "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")} className="buildStatus">
                  <span style={webStyle[row.attributes.status]}>{row.attributes.status || "-"}</span>
                </TableCell>
              )}
              <TableCell align={languageCondition(language, "right", "left")} className="view-page-menu buildid">
                <Menu
                  menuButton={
                    <IconButton>
                      <MoreVertIcon />
                    </IconButton>
                  }
                  className="buildid"
                  style={webStyle.btnMenu}
                >
                  <MenuItem
                    className="map-menu"
                    onClick={() =>
                      this.setState({ lat: row.attributes.lat, long: row.attributes.long }, () => this.handleMapModal())
                    }
                  >
                    {t("View location on map")}
                  </MenuItem>
                  <MenuItem className="sm-cancel-menu buildid">
                    <Link to={`/CompanyEmployee/BuildingDetailsPage/${row.id}`}>{t("Go to building page")}</Link>
                  </MenuItem>
                  {this.state.accountType === "account_manager" && row.attributes.status === "rejected" && (
                    <MenuItem onClick={this.handleRejectionModal} className="sm-reject-menu textid">
                      {t("View Rejection Reason")}
                    </MenuItem>
                  )}
                </Menu>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );

  handlePagination = () => {
    if (this.state.pagination) {
      return (
        <PaginationModule
          pagination={this.state.tabNo == 0 ? this.state.pagination : this.state.buildpagination}
          handlePagination={this.state.tabNo === 0 ? this.handleVistorPagination : this.buildingListPagination}
          page={this.state.tabNo === 0 ? this.state.page : this.state.buildpage}
        />
      );
    }
  };

  renderTableContent = (t: any, language: any) => {
    const commonContent = this.state.tabNo == 0 && (
      <TableContainer>
        <Table className="table-box">{this.handleCooComplexListing(t, language)}</Table>
      </TableContainer>
    );
    const commonbuildContent = this.state.tabNo !== 0 && (
      <TableContainer>
        <Table className="table-box">{this.handleCooBuildingListing(t, language)}</Table>
      </TableContainer>
    );
    return this.state.tabNo == 0 ? commonContent : commonbuildContent;
  };

  handleTableOne = (t: any) => {
    if (this.state.accountType !== "coo") {
      return t("Complex");
    } else {
      return t("List of Complexes");
    }
  };

  handleTableTwo = (t: any) => {
    if (this.state.accountType !== "coo") {
      return t("Individual Buildings");
    } else {
      return t("List of Individual Buildings");
    }
  };

  handleFilter = (t: any, language: any) => {
    if (this.state.accountType === "coo") {
      return (
        <React.Fragment data-testid="check_coo">
          <RealEstateFilter language={language} t={t} classComponent={this} filterList="coo_complex_filter" />
        </React.Fragment>
      );
    } else if (this.state.accountType === "sales_manager") {
      return (
        <RealEstateFilter language={language} t={t} classComponent={this} filterList="sales_manager_complex_filter" />
      );
    } else {
      return <RealEstateFilter language={language} classComponent={this} t={t} filterList="other_real_estate_filter" />;
    }
  };

  render() {
    //@ts-ignore
    const { t, i18n } = this.props;
    const language = i18n.language;
    return (
      <>
        <Loader loading={this.state.loading} />

        <Box maxWidth="xl" style={webStyle.detailsBox}>
          <Box>
            <Typography style={{ fontSize: "18px" }}>
              {t("Complex")} / {t("Buildings")}
            </Typography>
          </Box>
          <Box style={webStyle.titleBox}>
            <Typography style={webStyle.companyTitle} className="bold-text">
              {this.state.tabNo ? t("Individual Buildings") : t("Complex")}
            </Typography>
          </Box>
          <Box style={webStyle.tabsBox}>
            <Box>
              <TabsComponent
                tabNo={this.state.tabNo}
                handleChange={this.handleTabChange}
                tabs={[
                  {
                    label: t("Complex"),
                  },
                  {
                    label: t("Individual Buildings"),
                    testId: "tabcheck",
                  },
                ]}
              />
            </Box>
            <Button style={webStyle.registerBtn} onClick={this.handleOpenRegisterModal}>
              <span className="bold-text" data-testid="register_btn">
                {t("Register New Complex / Building")}
              </span>
            </Button>
          </Box>
          <Box style={webStyle.selectBoxes}>
            <Box style={webStyle.selectBoxLeft} className={languageCondition(language, "arabic-grid", "")}>
              <Box className="top-bar-filter">
                {this.handleFilter(t, language)}
                <Box className="filter-filter">
                  <Button
                    className="handleSearchFilterForInvoices search-btn-estate"
                    onClick={this.handleFilterSubmit}
                    startIcon={<SearchIcon style={{ marginLeft: "10px" }} />}
                  >
                    {t("Search")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={webStyle.tableContainer} className="complex_table_select_boxes_container">
            <Box style={webStyle.tableHeader} className="complex_table_select_boxes_header">
              <h2 style={webStyle.tableHeading} className="bold-text complex_table_heading2">
                <Box style={webStyle.switchIcons} className="complex_table_swicthes">
                  <Box
                    className={
                      this.state.mapSwitch ? "complex_table_swicthes_map" : "active_switch complex_table_swicthes_map"
                    }
                    style={LanguageAttributeHandler(language, webStyle.listIcon, webStyle.listIconAr)}
                    onClick={(e) => this.handleMapSwitch(e, false)}
                    data-testid="click_list"
                  >
                    <ListIcon
                      className={
                        this.state.mapSwitch
                          ? "complex_table_swicthes_map_icon"
                          : "active_switch_icon complex_table_swicthes_map_icon"
                      }
                    />
                  </Box>
                  <Box
                    className={
                      this.state.mapSwitch
                        ? "active_switch complex_table_swicthes_map_icon"
                        : "complex_table_swicthes_map_icon"
                    }
                    style={LanguageAttributeHandler(language, webStyle.roomIcon, webStyle.roomIconAr)}
                    data-testid="click_map"
                    onClick={(e) => this.handleMapSwitch(e, true)}
                  >
                    <RoomIcon
                      className={
                        this.state.mapSwitch
                          ? "active_switch_icon complex_table_swicthes_map_icon"
                          : "complex_table_swicthes_map_icon"
                      }
                    />
                  </Box>
                </Box>
                {this.state.tabNo === 0 ? this.handleTableOne(t) : this.handleTableTwo(t)}
              </h2>
              <Box className="filter-head-box" style={{ margin: "0" }}>
                {this.state.accountType !== "account_manager" && (
                  <FilterSelect
                    t={t}
                    language={language}
                    label="Sort By"
                    option={[
                      { label: "All", value: "all" },
                      { label: "Company Name", value: "company_name" },
                      { label: "Complex Name", value: "complex_name" },
                      { label: "Account Manager", value: "account_manager" },
                      { label: "Subscription Type", value: "subscription_type" },
                    ]}
                    value={this.state.sortBy}
                    onChange={this.handleSelectedSortBy}
                  />
                )}
                <SearchInput
                  onChange={this.handleComplexFilterDataKeyUp}
                  t={t}
                  language={language}
                  label={t("Search by complex name")}
                  data-testid="searchquery"
                />
              </Box>
            </Box>
            {this.state.mapSwitch ? (
              <Box style={webStyle.mapBox} className="complex_table_swicthes_map_switch">
                <ComplexMapComponent
                  defaultCenter={{
                    lat: 10.99835602,
                    lng: 77.01502627,
                  }}
                  defaultZoom={1}
                  mapPoints={this.state.mapPoints}
                  manageClick={this.handleMapPointClick}
                  currentPoint={this.state.mapPointClick}
                  navigation={this.props.navigation}
                  isBuilding={this.state.tabNo === 1}
                />
              </Box>
            ) : (
              <>
                {this.renderTableContent(t, language)}
                <Divider />
                <Box style={webStyle.tableFooter} className="paginate_details">
                  {this.handlePagination()}
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modalStyle"
          open={this.state.registerModal}
          onClose={this.handleCloseRegisterModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={this.state.registerModal}>
            <Box
              style={{
                width: "440px",
                marginTop: "15px",
                backgroundColor: "white",
                padding: "40px 20px",
                paddingBottom: "35px",
                borderRadius: "8px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <Typography className="bold-text" style={webStyle.modalRegisterHeading}>
                  {t("Register New")}
                </Typography>
                <CloseIcon
                  style={webStyle.closeIcon}
                  data-testid="close_register"
                  onClick={this.handleCloseRegisterModal}
                />
              </Box>
              <Box style={webStyle.buildingOptions}>
                {registerOptions.map((icon, i) => {
                  return (
                    <Box
                      style={webStyle.buildingOption}
                      key={i}
                      onClick={() => {
                        sessionStorage.setItem("isCreateComplex", "true");
                        this.props.navigation.navigate(icon.link);
                      }}
                      data-test-id="create-modal-complex"
                    >
                      <Box style={webStyle.buildingCircle}>
                        <img src={icon.icon} style={webStyle.iconSize} />
                      </Box>
                      <Typography>{t(icon.title)}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Fade>
        </Modal>

        <Modal
          onClose={this.handleRejectionModal}
          className="modalStyle"
          open={this.state.isRejectionModalOpen}
          BackdropComponent={Backdrop}
          closeAfterTransition
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={this.state.isRejectionModalOpen}>
            <Box
              style={{
                paddingBottom: "35px",
                marginTop: "15px",
                borderRadius: "8px",
                backgroundColor: "white",
                padding: "20px",
                width: "440px",
              }}
            >
              <Box
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Typography className="bold-text" style={webStyle.modalRegisterHeading}>
                  {t("Rejection Reason")}
                </Typography>
                <CloseIcon
                  style={webStyle.closeIcon}
                  onClick={this.handleCloseRegisterModal}
                  data-testid="close_register"
                />
              </Box>
              <Box style={webStyle.buildingOptions} sx={{ marginTop: "20px !important" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident mollitia repudiandae ea quaerat
                expedita quo hic dolores iusto sint minima rem harum, laboriosam saepe voluptate alias eius aspernatur
                porro libero.
              </Box>
            </Box>
          </Fade>
        </Modal>

        <MapDialog
          t={t}
          language={language}
          IsOpen={this.state.isMapModalOpen}
          CloseDialog={this.handleMapModal}
          lat={this.state.lat}
          long={this.state.long}
        />
      </>
    );
  }
}

const webStyle: any = {
  approved: {
    background: "rgb(30,198,91,0.2)",
    color: "rgb(30 198 91)",
    textAlign: "center",
    padding: "5px 15px",
    borderRadius: "16px",
    fontSize: "12px",
  },
  btnMenu: {
    padding: "0px !important",
  },
  rejected: {
    background: "rgb(242,23,23, 0.2)",
    color: "red",
    textAlign: "center",
    padding: "5px 10px",
    borderRadius: "25px",
    fontSize: "12px",
  },
  Pending: {
    color: "#FC8434",
    background: "#FFEDE0",
    textAlign: "center",
    padding: "5px 10px",
    borderRadius: "25px",
    fontSize: "12px",
  },
  buildingOption: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
  },
  buildingLink: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
  },
  noRecord: {
    padding: "30px",
    textAlign: "center",
  },
  buildingOptions: {
    display: "flex",
    marginTop: "50px",
    justifyContent: "space-evenly",
  },
  buildingCircle: {
    border: "1px solid #ececec",
    borderRadius: "50%",
    height: "80px",
    width: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "15px",
  },
  pending_approval: {
    background: "rgb(252, 132, 52, 0.2)",
    color: "rgb(252, 132, 52)",
    padding: "5px 10px",
    borderRadius: "25px",
    fontSize: "12px",
  },
  modalRegisterHeading: {
    fontSize: "20px",
  },
  switchIcons: {
    display: "flex",
    cursor: "pointer",
  },
  closeIcon: {
    position: "absolute",
    right: "-4px",
    top: "-5px",
    cursor: "pointer",
  },
  listIconAr: {
    color: "#dcdcdc",
    border: "1px solid #dcdcdc",
    borderRadius: "0px 8px 8px 0px",
    padding: "4px",
    fontSize: "18px",
  },
  listIcon: {
    color: "#dcdcdc",
    border: "1px solid #dcdcdc",
    borderRadius: "8px 0px 0px 8px",
    padding: "4px",
    fontSize: "18px",
  },
  roomIcon: {
    color: "#dcdcdc",
    border: "1px solid #dcdcdc",
    borderRight: "1px solid #dcdcdc",
    borderRadius: "0px 8px 8px 0px",
    padding: "4px",
    borderLeft: "0px",
    fontSize: "18px",
  },
  roomIconAr: {
    color: "#dcdcdc",
    border: "1px solid #dcdcdc",
    borderLeft: "1px solid #dcdcdc",
    borderRadius: "8px 0px 0px 8px",
    padding: "4px",
    borderRight: "0px",
    fontSize: "18px",
  },
  mapBox: {
    width: "100%",
    height: "400px",
    overflow: "hidden",
  },
  selectBoxLeft: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  rowOptions: {
    position: "absolute" as CSSProperties["position"],
    background: "#fff",
    width: "160px",
    left: "-115px",
    boxShadow: "0px 0px 6px rgba(87,148,201,0.18)",
    zIndex: "9999999" as CSSProperties["zIndex"],
    padding: "15px",
  },
  option1: {
    marginBottom: "15px" as CSSProperties["marginBottom"],
  },
  rowList: {
    listStyle: "none",
  },
  complexNameBox: {
    background: "#fff",
    padding: "10px 15px",
    width: "120px",
    textAlign: "center",
  },
  complexName: {
    fontSize: "12px",
  },
  selectBoxRight: {
    width: "25%",
    display: "flex",
    justifyContent: "flex-end",
  },
  selectBoxes: {
    marginTop: "30px",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  currentRecords: {
    color: "#FC8434",
  },
  searchBtn: {
    marginTop: "5px",
    marginLeft: "15px",
    height: "calc(100% - 20px)",
    background: "#2B6FED",
    borderColor: "#2B6FED",
    fontSize: "14px",
    boxShadow: "unset",
  },
  searchText: {
    marginLeft: "10px",
  },
  detailsBox: {
    padding: "0 30px 30px",
  },
  tabsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  registerBtn: {
    background: "#FC8434",
    color: "#fff",
    height: "40px",
    paddingLeft: "20px",
    paddingRight: "20px",
    borderRadius: "8px",
  },
  searchInput: {
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: "8px",
    height: "40px",
    padding: "0px",
    color: "#212121",
  },
  moreOptions: {
    position: "relative" as CSSProperties["position"],
  },
  moreIcon: {
    color: "rgba(136,136,136,0.6)",
    cursor: "pointer",
  },
  tableHeader: {
    padding: "20px",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableContainer: {
    background: "#fff",
  } as React.CSSProperties,
  tableFooter: {
    padding: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableHeading: {
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    gap: "18px",
  },
  menuOption: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  innerInput: {
    padding: "0px",
    color: "#212121",
  },
  titleBox: {
    marginTop: "0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "18px",
  },
  companyTitle: {
    fontSize: "32px",
  },
  companyTag: {
    color: "#FC8434",
    background: "rgb(252, 132, 52, 0.1)",
    padding: "5px 15px",
    borderRadius: "12px",
  },
};

export default withTranslation()(ComplexListingTable);
// Customizable Area End
