// Customizable Area Start
import React from "react";
import {
    Box,
    Breadcrumbs,
    Button,
    Chip,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Popover,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    styled,
} from "@material-ui/core";
import LeaseManagementIssueNewLeaseController, { Props, 
    SingleBuilding, 
    AllUnitNumberListDatainterface, 
    GetSelectComplexListResponseItem,
    GetFindPersonListItem,
    TenantDetailsData,
    UserTypeInterface, 
    TFunction
    } from "./LeaseManagementIssueNewLeaseController.web";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import PhoneInput from "react-phone-input-2";
import AlertSuccess from "../../../components/src/AlertSuccess.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BuildingIcon, LocationIcon, TanentProfileIcon, UnitIcon, email_icon, user_icon,greyPhoneNumber, IdCartImage } from "./assets";
import Search from "@material-ui/icons/Search";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withTranslation } from "react-i18next";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import ClearIcon from "@material-ui/icons/Clear";
import MapDialog from "../../../components/src/MuiDialog/MapDialog.web";

// Customizable Area End

// Customizable Area Start
// Customizable Area End

class LeaseManagementIssueNewLease extends LeaseManagementIssueNewLeaseController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area Start
    }

    // Customizable Area Start 
    renderIssueLeaseForm = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <Paper elevation={2} className="issueLeaseFormPaper">
                <FormControl variant="outlined" fullWidth>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label={t("Select Country")}
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        className="leaseIssueLeaseSelect"
                        value={this.state.country}
                        onChange={this.hanldeCountryChange}
                        displayEmpty
                        data-testId={"countrySelectTestId"}
                    > 
                        <MenuItem value={""}>{t("Select Country")}</MenuItem>
                        {this.state.countryList.map((country: string, index: number) => {
                            return <MenuItem value={country} key={index}>{country}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label={t("Select City")}
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        className="leaseIssueLeaseSelect"
                        displayEmpty
                        value={this.state.city}
                        onChange={this.hanldeCityChange}
                        data-testId={"citySelectTestId"}
                        disabled={this.state.country?false:true}
                    >                  
                        <MenuItem value={""}>{t("Select City")}</MenuItem>
                        {this.state.cityList.map((city: string, index: number) => {
                            return <MenuItem value={city} key={index}>{city}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label={t("Complex")}
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        className="leaseIssueLeaseSelect"
                        displayEmpty
                        value={this.state.complex}
                        onChange={this.hanldeComplexChange}
                        data-testId={"complexTestId"}
                        disabled={this.state.city?false:true}
                    >                    
                        <MenuItem value={""}>{t("Select Building Type")}</MenuItem>
                        <MenuItem value={"Complex"}>{t("Complex")}</MenuItem>
                        <MenuItem value={"Individual Building"}>{t("Individual Building")}</MenuItem>
                    </Select>
                </FormControl>
                {this.state.complex !== "Individual Building" && 
                  <FormControl variant="outlined" fullWidth>
                  <Select
                      MenuProps={{
                          style: {
                              marginTop: "50px",
                          },
                      }}
                      id="demo-simple-select-outlined"
                      label={t("Select Complex")}
                      labelId="demo-simple-select-outlined-label"
                      IconComponent={ExpandMoreIcon}
                      className="leaseIssueLeaseSelect"
                      displayEmpty
                      value={this.state.selectComplex}
                      onChange={this.hanldeSelectComplexChange}
                      disabled={this.state.complex?false:true}
                      data-testId={"selectComplexTestID"}
                  >
                  
                  <MenuItem value={""}>{t("Select Complex")}</MenuItem>
                      {this.state.selectComplexList.map((item:GetSelectComplexListResponseItem,index:number)=>{
                          return <MenuItem value={item.attributes.name}>
                          {item.attributes.name}
                      </MenuItem>
                      })}
                  </Select>
              </FormControl>}
                <FormControl variant="outlined" fullWidth>
                    <Select
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-outlined-label"
                        IconComponent={ExpandMoreIcon}
                        label={t("Select Building")}
                        className="leaseIssueLeaseSelect"
                        displayEmpty
                        value={this.state.building}
                        disabled={this.state.complex === "Complex" && this.state.selectComplex || this.state.complex === "Individual Building" ? false : true}
                        onChange={this.hanldeBuildingChange}
                        data-testId={"buildingSelectTestId"}
                    >                    
                        <MenuItem value={""}>{t("Select Building")}</MenuItem>
                        {this.state.buildingList.map((buildingName: SingleBuilding, index: number) => {
                            return <MenuItem key={index} value={buildingName.name}>{buildingName.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                    <Select
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        id="demo-simple-select-outlined"
                        label={t("Select Unit")}
                        labelId="demo-simple-select-outlined-label"
                        IconComponent={ExpandMoreIcon}
                        className="leaseIssueLeaseSelect"
                        displayEmpty
                        value={this.state.unit}
                        onChange={this.hanldeUnitChange}
                        disabled={this.state.building?false:true}
                        data-testId={"unitSelectTestId"}
                    >                    
                        <MenuItem value={""}>{t("Select Unit")}</MenuItem>
                        {this.state.unitNumberList.map((unit: AllUnitNumberListDatainterface, index: number) => {
                            return <MenuItem key={index} value={unit.apartment_name}>{unit.apartment_name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Paper>
        );
    };

    renderOwenerDetails = () => {
        const { t }: { t: TFunction } = this.props;
        return <Box className="ownerDetailsContainer" component={Paper}>
            <Box className="ownerDetailsHeader">
                <Typography variant="h2" className="ownerHeading">{t("Owner Details")}</Typography>
            </Box>
            <Divider/>
            <Box className="ownerDetailsSection">
                <Box className="ownerDetailsSection1">
                    <img src={TanentProfileIcon}/>
                    <Box>
                        <Typography className="tanentNameLabel">{t("Owner Name")}</Typography>
                        <Typography className="tanentName">{this.state.ownerTenantActiveContract.data.attributes.owner_details.owner_name}</Typography>
                    </Box>
                </Box>
                <Box className="ownerDetailsSection1">
                    <img src={greyPhoneNumber}/>
                    <Box>
                        <Typography className="tanentNameLabel">{t("Phone Number")}</Typography>
                        <Typography className="tanentName">{this.state.ownerTenantActiveContract.data.attributes.owner_details.owner_number}</Typography>
                    </Box>
                </Box>
                <Box className="ownerDetailsSection1">
                    <img src={email_icon}/>
                    <Box>
                        <Typography className="tanentNameLabel">{t("Email Address")}</Typography>
                        <Typography className="tanentName">{this.state.ownerTenantActiveContract.data.attributes.owner_details.email}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    }

    renderTenantDetails = () => {
        const { t }: { t: TFunction } = this.props;
        return <Box className="tenantDetailsContainer" component={Paper}>
            <Box className="tenantheadingContainer">
                <Typography className="tenantHeading" data-testId={"tenantDetailsHeadingTestId"}>{t("Tenant Details")}</Typography>
                <Box>
                    <Typography className="addsubHeading" onClick={this.handleOpenFindPersonModal} data-testId={"addAnotherTenantTestId"}>+ {t("ADD ANOTHER TENANT")}</Typography>
                </Box>
            </Box>
            <Divider />
            <Box className="tanentProfileContainer">
                {this.state.ownerTenantActiveContract.data.attributes.tenant_details.length > 0 && this.state.ownerTenantActiveContract.data.attributes.tenant_details.map((tenant: TenantDetailsData, index: number) => {
                    return <Box className="tanetprofileNameContainer" key={index}>
                        <Box className="tenantLeft">
                            <Box>
                                <img src={TanentProfileIcon} className="tanentProfileIcon" />
                            </Box>
                            <Box>
                                <Typography className="tanentNameLabel">{t("Tenant Name")}</Typography>
                                <Box className="tenantNameContainer">
                                    <Typography className="tanentName">{tenant.tenant_name}</Typography>
                                    {tenant.expires_on !== "" &&
                                        <Chip
                                            size="small"
                                            label={this.handleExpireContractLabel(tenant.expires_on)}
                                            clickable
                                            color="primary"
                                            className="expireChip"
                                        />
                                    }
                                    {tenant.expires_on === "" &&
                                        <Chip
                                            size="small"
                                            label={`Contract starts from ${tenant.start_date}`}
                                            clickable
                                            color="primary"
                                            className="startDateChip"
                                        />
                                    }
                                </Box>
                            </Box>
                        </Box>
                        <Box className="tenantRight">
                        {tenant.expires_on !== "" && this.calculateDaysUntilExpiry(tenant.expires_on) && (
                         <Box>
                            <Button 
                            className="renewContractBtn" 
                            onClick={() => this.handleIssueNewContract(tenant.tenant_name, tenant.id, "renew")} 
                            data-testId={"issueNewContractBtnTestId"}
                         >
                         {t("Renew Contract")}
                        </Button>
                        </Box>

                        )}

                            {tenant.expires_on === "" &&
                                <Box>
                                    <Button className="issueNewContract" onClick={() => this.handleIssueNewContract(tenant.tenant_name, tenant.id,"create")} data-testId={"issueNewContractBtnTestId"}>
                                        {t("Issue a New Contract")}
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Box>
                })}
                            </Box>
        </Box>
    }

    renderNoIssuedContracts = () => {
        const { t }: { t: TFunction } = this.props;

        return <Box className="noIssuesContractsContainer">
            <Typography className="noIssuesContractsMessage">{t("Tenant registered. No issued contracts")}</Typography>
        </Box>
    }

    renderActiveContracts = () => {
        const { t }: { t: TFunction } = this.props;

        return <Box className="activeContractsContainer">
            <Box className="activeContractHeadingContainer">
                <Typography className="activeContractHeading">{t("Active Contract")}</Typography>
                <Box>
                    <Button className="viewDetailsBtn" data-testId={"viewContractDetailsBtnTestId"} onClick={()=>this.handleViewContract(this.state.ownerTenantActiveContract.data.attributes.contract_details.contract.id)}>{t("View Details")}</Button>
                </Box>
            </Box>
            <Divider />
            <Box className="activeContractListContainer">
                <Box className="activeContractListItem">
                    <Typography className="activeContractListItemLabel">{this.handleExpireDateLabel(this.state.ownerTenantActiveContract.data.attributes?.contract_details?.expires_on)}: </Typography>
                    <Typography className="activeContractListItemTitle">{this.state.ownerTenantActiveContract.data.attributes?.contract_details?.contract?.expires_on}</Typography>
                </Box>
                <Divider />
                <Box className="activeContractListItem">
                    <Typography className="activeContractListItemLabel">{t("Complex")}: </Typography>
                    <Typography className="activeContractListItemTitle">{this.state.selectComplex}</Typography>
                </Box>
                <Divider />
                <Box className="activeBuildingContainer">
                    <Box className="activeContractListItem">
                        <Typography className="activeContractListItemLabel">{t("Building")}: </Typography>
                        <Typography className="activeContractListItemTitle">{this.state.building}</Typography>
                    </Box>
                    <Box className="activelocationContainer">
                        <img src={LocationIcon} alt="" />
                        <Typography className="buildingOnMapText" onClick={this.handleBuildingModal} data-testId={"viewBuildingBtnTestId"}>{t("See building on map")}</Typography>
                    </Box>
                </Box>
                <Divider />
                <Box className="activeContractListItem">
                    <Typography className="activeContractListItemLabel">{t("Unit")}: </Typography>
                    <Typography className="activeContractListItemTitle">{this.state.unit}</Typography>
                </Box>
                <Divider />
                <Box className="activeContractListItem">
                    <Typography className="activeContractListItemLabel">{t("Tenant Name")}: </Typography>
                    <Typography className="activeContractListItemTitle">{this.state.ownerTenantActiveContract.data.attributes?.contract_details?.contract?.tenant_name}</Typography>
                </Box>
                <Divider />
                <Box className="activeContractListItem">
                    <Typography className="activeContractListItemLabel">{t("Owner Name")}: </Typography>
                    <Typography className="activeContractListItemTitle">{this.state.ownerTenantActiveContract.data.attributes?.contract_details?.contract?.landlord_name}</Typography>
                </Box>
                <Divider />
                <Box className="activeContractListItem">
                    <Typography className="activeContractListItemLabel">{t("Contract Type")}: </Typography>
                    <Typography className="activeContractListItemTitle">{t("Rent Contract")}</Typography>
                </Box>
                <Divider />
            </Box>
            <Box className="activeContractFooterContainer">
                <Typography className="activeContractFooterText">
                    {t("Contract is already assigned to")} <span className="activeContractFooterBoldText">{this.state.ownerTenantActiveContract.data.attributes.contract_details.tenant_name}</span> {t("for")} <span className="activeContractFooterBoldText">{this.state.building}</span>, <span className="activeContractFooterBoldText">{this.state.unit}</span>. {t("You will have to end or terminate contract in order to issue a new contract.")}
                </Typography>
            </Box>
        </Box>
    }

    renderFindPersonModal = () => {
        const { anchorEl, selectedId } = this.state;
        const open = Boolean(anchorEl);
        const popoverId = open ? "simple-popover" : undefined;
        const { t }: { t: TFunction } = this.props;
        return <Modal open={this.state.findPersonModal}>
            <IssueNewLeaseModalContainer>
                <Box className="issueNewModalContainer">
                    <Box className="issueNewLeaseModalHeader">
                        <Typography className="issueNewLeaseModalHeading" data-testId={"findPersonModalTestId"}>{t("Find Person")}</Typography>
                        <Typography className="issueNewLeaseModalHeading" onClick={this.handleCloseFindPersonModal}>X</Typography>
                    </Box>
                    <Divider />
                    <Box className="issueNewModalContentcontainer">
                        <Typography className="issueNewModalContentheading">{t("Fill one of the more of the fields to find the person, if two fields are populated, any matching person will be shown.")}</Typography>
                        <Box className="issueNewLeasefieldsContiner">
                            <Box className="issueNewLeasefieldsRow">
                                <Box className="issueNewLeasefieldsRowItem">
                                    <InputLabel className="issueNewLeasefieldsLabel">{t("ID Number")}</InputLabel>
                                    <TextField variant="outlined" fullWidth placeholder={t("ID Number")} onChange={this.handleFindPersonIdNumberChange} data-testId={"findPersonIdNumberTestId"} />
                                </Box>
                                <Box className="issueNewLeasefieldsRowItem">
                                    <InputLabel className="issueNewLeasefieldsLabel">{t("Passport Number")}</InputLabel>
                                    <TextField variant="outlined" fullWidth placeholder="Passport Number" onChange={this.handleFindPersonPassportChange} data-testId={"findPersonPassportTestId"} />
                                </Box>
                                <Box className="issueNewLeasefieldsRowItem">
                                    <InputLabel className="issueNewLeasefieldsLabel">{t("Email ID")}</InputLabel>
                                    <TextField variant="outlined" fullWidth placeholder={t("Email ID")} onChange={this.handleFindPersonEmailChange} data-testId={"findPersonEmailTestID"} />
                                </Box>
                                <Box className="issueNewLeasefieldsRowItem">
                                    <InputLabel className="issueNewLeasefieldsLabel">{t("Phone Number")}</InputLabel>
                                    <TextField variant="outlined" fullWidth placeholder={t("Phone Number")} onChange={this.handleFindPersonPhoneNumberChange} data-testId={"findPersonPhoneTestId"} />
                                </Box>
                                <Box className="searchbtnContainer">
                                    <Button
                                        variant="contained"
                                        startIcon={<Search />}
                                        className="modalSearchBtn"
                                        fullWidth
                                        onClick={this.findPersonListApiCall}
                                        data-testId={"findPersonSearchBtnTestId"}
                                    >
                                        {t("Search")}
                                    </Button>
                                </Box>
                            </Box>
                            {this.state.findPersonList.length === 0 ? "" :
                            <Box className="tableContainer">
                                <TableContainer component={Paper} style={{height: "42%"}}>
                                    <Table aria-label="simple table" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left" className="tableHead">{t("Name")}</TableCell>
                                                <TableCell align="left" className="tableHead">{t("ID Number")}</TableCell>
                                                <TableCell align="left" className="tableHead">{t("Phone Number")}</TableCell>
                                                <TableCell align="left" className="tableHead">{t("Email")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.findPersonList.map((item: GetFindPersonListItem, index: number) => {
                                                return <TableRow key={index}><TableCell align="left">{this.handleNullValue(item.attributes.full_name)}</TableCell>
                                                    <TableCell align="left">{this.handleNullValue(item.id)}</TableCell>
                                                    <TableCell align="left">{this.handleNullValue(item.attributes.full_phone_number)}</TableCell>
                                                    <TableCell align="left">{this.handleNullValue(item.attributes.email)}</TableCell>
                                                    <TableCell align="left">
                                                        <Box className="templateMenuBtnContainer">
                                                            <IconButton
                                                                data-testId={`templateMenubtn${index}`}
                                                                onClick={(event) => this.handleClick(event, Number(item.id))}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Popover
                                                                open={open && selectedId === Number(item.id)}
                                                                id={popoverId}
                                                                onClose={this.handleClose}
                                                                anchorEl={anchorEl}
                                                                data-testId={`menuPopoverCloseTestId${index}`}
                                                                transformOrigin={{
                                                                    horizontal: "right",
                                                                    vertical: "top",
                                                                }}
                                                                anchorOrigin={{
                                                                    horizontal: "left",
                                                                    vertical: "bottom",
                                                                }}
                                                            >
                                                                <PopoverContainer>
                                                                    <Typography className="popoverText" onClick={()=>this.hanldeViewPersonDetails(item)} data-testId={`isssueNewLease${index}`}>
                                                                        {t("View")}
                                                                    </Typography>
                                                                </PopoverContainer>
                                                            </Popover>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>}
                            {this.state.showAddNewPersonButton?
                            <Box className="noRecordContainer">
                                <Typography className="noRecordHeading">{t("No Record Found")}</Typography>
                                <Button className="noRecordBtn" onClick={this.handleAddNewPerson} data-testId={"addnewPersonTestId"}>{t("Add New Person")}</Button>
                            </Box>:
                            <Box className="issueNewLeaseModalBtnContainer" >
                                <Button className="issueNewLeaseModalBtn" onClick={this.handleCloseFindPersonModal} data-testId={"cancelBtnTestId"}>{t("Cancel")}</Button>
                            </Box>}
                            <Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

            </IssueNewLeaseModalContainer>
        </Modal>
    };

    renderFindShowPersonModal = () => {
        const { t }: { t: TFunction } = this.props;
        const {selectedPersonDetails} =this.state        
        return (
          <Modal open={this.state.openFindShowPersonModal} data-testId={"findPersonShowModalTestId"}>
            <OpenFindShowPersonModalContainer>
              <Box className="openFindShowPersonModalContainer">
                <Box className="showPersonHeadingContainer">
                  <Typography className="showPersonHeading" data-testId={`findShowPersonModalHeadingTestId`}>{t("Find Person")}</Typography>
                  <IconButton onClick={this.handleClosePersonDetailsModal}>
                    <ClearIcon />
                  </IconButton>
                </Box>
                <Divider />
                <Box className="showPersonContentContainer">
                  <Grid container spacing={3}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <Typography className="showPersonFieldLable">{t("Name")}</Typography>
                      <Typography className="showPersonFieldValue">
                      {selectedPersonDetails.attributes.full_name}
                      </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <Typography className="showPersonFieldLable">
                      {t("Complex")}
                      </Typography>
                      <Typography className="showPersonFieldValue">
                        {selectedPersonDetails.attributes?.complex?.name}
                      </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <Typography className="showPersonFieldLable">
                      {t("ID Number")}
                      </Typography>
                      <Typography className="showPersonFieldValue">
                      {selectedPersonDetails.id}
                      </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <Typography className="showPersonFieldLable">
                      {t("Email")}
                      </Typography>
                      <Typography className="showPersonFieldValue">
                        {selectedPersonDetails.attributes.email}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="showPersonFieldLable">
                      {t("Address")}
                      </Typography>
                      <Typography className="showPersonFieldValue">
                        {selectedPersonDetails.attributes.address.address}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box className="showPersonBtnContainer">
                        <Button
                          className="showPersonBtnOutlined"
                          onClick={this.handleClosePersonDetailsModal}
                          data-testId={`findShowPersonModalCancelBtnTestId`}
                        >
                          {t("Cancel")}
                        </Button>
                        <Button className="showPersonBtnContained" onClick={this.handleSelectFindPerson} data-testId={"selectFindPersonTestId"}>{t("Select")}</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </OpenFindShowPersonModalContainer>
          </Modal>
        );
    };

    renderAdddnewPersonModal = () => {
        const { t }: { t: TFunction } = this.props;

        return <Modal open={this.state.addNewPersonModal}>
            <IssueNewLeaseModalContainer>
                <Box className="invitationNewModalContainer">
                    <Box className="issueNewLeaseModalHeader">
                        <Typography className="issueNewLeaseModalHeading" data-testId={"addnewPersonModalHeading"} >{t("Find Person")}</Typography>
                        <Typography className="issueNewLeaseModalHeading">X</Typography>
                    </Box>
                    <Divider />
                    <Box className="addNuwPersonModalFormContainer">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel" id="demo-simple-select-outlined-label">{t("Select User Type")}</InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        label={t("Recident")}
                                        IconComponent={ExpandMoreIcon}
                                        labelId="demo-simple-select-outlined-label"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img src={user_icon} alt="" />
                                            </InputAdornment>
                                        }
                                        displayEmpty
                                        value={this.state.findNewPersonUserType}
                                        onChange={this.handleFindPersonUserTypeChange}
                                        data-testId={"userTypeTestId"}
                                    >
                                        <MenuItem value="">
                                            {t("Select User Type")}
                                        </MenuItem>
                                        {this.state.userTypeList.map((user: UserTypeInterface, userIndex: number) => {
                                            return <MenuItem key={userIndex} value={user.attributes.name}>{user.attributes.name}</MenuItem>
                                        })}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel">{t("Member full name")}</InputLabel>
                                <TextField fullWidth variant="outlined" InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <img src={user_icon} alt="" />
                                        </InputAdornment>

                                }}
                                value={this.state.findNewPersonMemberName}
                                onChange={this.handleFindPersonMemberNameChange}
                                placeholder="Enter Member Full Name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel">{t("Email Address")}</InputLabel>
                                <TextField fullWidth variant="outlined" InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <img src={email_icon} alt="" />
                                        </InputAdornment>

                                }}
                                value={this.state.findNewPersonEmail}
                                onChange={this.handleFindNewPersonEmailChange}
                                placeholder="Email Address"
                                />
                            </Grid>
                             <Grid item xs={12} sm={6} md={6} lg={6}>                               
                                <InputLabel className="issueNewLeasefieldsLabel">{t("Phone Number")}</InputLabel>
                                <Box className="mobile-box register-tenant-country-code-selector">
                                    <Box className="register-tenant-select-box">
                                      <FormControl variant="outlined">
                                        <PhoneInput
                                          onChange={()=>this.handleCountryCode}
                                          country={"us"}
                                          inputProps={{ name: "selectCode" }}
                                          enableSearch={true}
                                        />
                                      </FormControl>
                                    </Box>
                                    <Box className="divider" />
                                    <Input
                                      startAdornment={
                                        <InputAdornment position="start">
                                          <img src={greyPhoneNumber} alt="" />
                                        </InputAdornment>
                                      }
                                      name="tenantMobile"
                                      className="mobile-input"
                                      onChange={this.handleFindpersonPhoneNumber}
                                      placeholder={t("Tenant Mobile")}
                                    />
                                  </Box>
                            </Grid> 
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel">{t("Select Country")}</InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        IconComponent={ExpandMoreIcon}
                                        label="Country"
                                        displayEmpty
                                        value={this.state.country}
                                        onChange={this.hanldeCountryChange}
                                    >
                                        <MenuItem value={""}>{t("Country")}</MenuItem>
                                        {this.state.countryList.map((country: string, index: number) => (
                                            <MenuItem key={index} value={country}>
                                                {country}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid> 
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel" >{t("Select City")}</InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        label={t("City")}
                                        IconComponent={ExpandMoreIcon}
                                        displayEmpty
                                        value={this.state.city}
                                        onChange={this.hanldeCityChange}
                                    >
                                        <MenuItem value={""}>{t("City")}</MenuItem>
                                        {this.state.cityList.map((city: string, index: number) => (
                                            <MenuItem key={index} value={city}>
                                                {city}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel" >{t("Select Complex/Indivisual Building")}</InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        IconComponent={ExpandMoreIcon}
                                        label={t("Select Complex/Indivisual Building")}
                                        displayEmpty
                                        value={this.state.complex}
                                        onChange={this.hanldeComplexChange}
                                    >
                                        <MenuItem value={""}>{t("Select Complex")}</MenuItem>
                                        <MenuItem value={"Complex"}>{t("Complex")}</MenuItem>
                                        <MenuItem value={"Indivisual"}>{t("Indivisual")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                             <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel" >{t("Select Complex")}</InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        label={t("Select Complex")}
                                        id="demo-simple-select-outlined"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img src={BuildingIcon} alt="" />
                                            </InputAdornment>
                                        }
                                        IconComponent={ExpandMoreIcon}
                                        displayEmpty
                                        value={this.state.selectComplex}
                                        onChange={this.hanldeSelectComplexChange}
                                    >
                                        <MenuItem value={""}>{t("Select Complex")}</MenuItem>
                                        {this.state.selectComplexList.map((complex: GetSelectComplexListResponseItem, index: number) => (
                                            <MenuItem key={index} value={complex.attributes.name}>
                                                {complex.attributes.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid> 
                             <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel" >{t("Select Building")}</InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        id="demo-simple-select-outlined"
                                        label={t("Select Building")}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img src={BuildingIcon} alt="" />
                                            </InputAdornment>
                                        }
                                        labelId="demo-simple-select-outlined-label"
                                        IconComponent={ExpandMoreIcon}
                                        displayEmpty
                                        value={this.state.building}
                                        onChange={this.hanldeBuildingChange}
                                    >
                                        <MenuItem value={""}>{t("Select Building")}</MenuItem>
                                        {this.state.buildingList.map((building: SingleBuilding, index: number) => (
                                            <MenuItem key={index} value={building.name}>
                                                {building.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid> 
                             <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel" >{t("Select Unit")}</InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        id="demo-simple-select-outlined"
                                        labelId="demo-simple-select-outlined-label"
                                        label={t("Recident")}
                                        IconComponent={ExpandMoreIcon}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img src={UnitIcon} alt="" />
                                            </InputAdornment>
                                        }
                                        displayEmpty
                                        value={this.state.unit}
                                        onChange={this.hanldeUnitChange}
                                    >
                                        <MenuItem value={""}>{t("Select Unit")}</MenuItem>
                                        {this.state.unitNumberList.map((unit:AllUnitNumberListDatainterface,index:number)=>{
                                            return <MenuItem key={index} value={unit.apartment_name}>{unit.apartment_name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid> 
                        </Grid>
                    </Box>
                    <Box className="findPersonModalBtnContainer">
                        <Button className="findPersonModalBtn" onClick={this.handleCloseAddNewPersonModal} data-testId={"modalCancelBtnTestId"}>{t("Cancel")}</Button>
                        <Button className="findPersonModalSendBtn" onClick={this.sendInvitation} data-testId={"sendInvitationTestId"}>{t("Send Invitation")}</Button>
                    </Box>
                </Box>
            </IssueNewLeaseModalContainer>
        </Modal>
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { t }: { t: TFunction } = this.props;
        return (
            <MainScreen>
                <IssuLeeaseContainer>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="inherit" id={"LeaseManagementId"} onClick={this.handleExistRecordNavigation} data-testId="issueNewLeaseTestID" className={"issueNewLeaseHeadingNavigation"}>
                            {t("Lease Management")}
                        </Typography>
                        <Typography className="issueNewLeaseText">{t("Issue a New Lease")}</Typography>
                    </Breadcrumbs>
                    <Typography className="issueNewLeaseHeading">
                        {t("Issue a New Lease")}
                    </Typography>
                    {this.renderIssueLeaseForm()}
                    {this.state.showOwnerDetails && this.renderOwenerDetails()}
                    {this.renderTenantDetails()}
                    {!this.state.showContractDetails && this.renderNoIssuedContracts()}
                    {this.state.showContractDetails && this.renderActiveContracts()}
                    {this.renderFindPersonModal()}
                    {this.renderAdddnewPersonModal()}
                    {this.renderFindShowPersonModal()}
                    <AlertErrorWeb show={this.state.showError} handleClose={this.handleCloseAlertError} message={this.state.error} data-testId={"errorAlertTesId"} />
                    <AlertSuccess show={this.state.showSuccessMessage} handleClose={this.hanldeCloseSuccessAlert} message={this.state.successMessage} data-testId={"successAlertTesId"} />
                    <MapDialog
                        t={this.props.t}
                        IsOpen={this.state.openBuildingModal}
                        CloseDialog={this.handleCloseViewBuilding}
                        lat={this.state.latitude}
                        long={this.state.longitude}
                        data-testId={"buildingModalTestId"}
                    />
                </IssuLeeaseContainer>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(LeaseManagementIssueNewLease);
export { LeaseManagementIssueNewLease };

const IssuLeeaseContainer = styled(Box)({
    padding: "50px",
    backgroundColor: "#f4f7ff",
    "& .issueNewLeaseHeading": {
        fontSize: "35px",
        fontWeight: 800,
        cursor:"pointer"
    },
    "& .issueNewLeaseHeadingNavigation": {
        fontWeight: 800,
        cursor:"pointer"
    },
    "& .issueLeaseFormPaper": {
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        borderRadius: "8px",
        padding: "20px",
        marginTop: "40px",
    },
    "& .leaseIssueLeaseSelect": {
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            backgroundColor: "#f9f9f9 !important",
            border: "1px solid #f2f1f1 !important",
            borderRadius: "8px !important",
        },
        '& #demo-simple-select-outlined': {
            color: 'black !important',
        }
    },
    "& .selectPlaceholder":{
        color:"gray !important"
    },
    "& .tenantDetailsContainer": {
        marginTop: "20px",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        padding: "20px",
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        flexDirection: "column"

    },
    "& .tanentProfileIcon": {
        marginTop: "5px"
    },
    "& .tenantHeading": {
        fontWeight: 700,
        fontSize: "20px",
        color: "#2e2f35"
    },
    "& .addsubHeading": {
        fontWeight: 900,
        color: "#da8d6d",
        fontSize: "16px",
        cursor:"pointer"
    },
    "& .tenantheadingContainer": {
        display: "flex",
        justifyContent: "space-between",

    },
    "& .tanentProfileContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "20px",
        flexDirection: "column",
        gap: "20px"
    },
    "& .tanetprofileNameContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
    },
    "& .tenantLeft": {
        display: "flex",
        gap: "10px"
    },
    "& .tenantNameContainer": {
        display: "flex",
        gap: "10px"
    },
    "& .expireChip": {
        backgroundColor: "#FFF6EF",
        color: "#fc8434",
        padding: "0px 10px"
    },
    "& .startDateChip": {
        backgroundColor: "#f0f5fe",
        color: "#2b6fed",
        padding: "0px 10px"
    },
    "& .issueNewContract": {
        color: "#fff !important",
        fontWeight: 800,
        backgroundColor: "#f88538",
        height: "50px",
        padding: "0px 20px",
        borderRadius: "8px",
        width: "220px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase"
    },
    "& .renewContractBtn": {
        color: "#3170da !important",
        fontWeight: 800,
        backgroundColor: "#fff",
        height: "50px",
        padding: "0px 20px",
        borderRadius: "8px",
        width: "220px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #3170da",
        textTransform: "uppercase",
        "&.MuiButton-root.Mui-disabled":{
            opacity: 0.5
        }
    },
    "& .tanentNameLabel": {
        color: "#b6b6b6",
        fontSize: "16px"
    },
    "& .tanentName": {
        color: "#1c1d20",
        fontSize: "18px",
        fontWeight: 900
    },
    "& .noIssuesContractsContainer": {
        height: "200px",
        borderRadius: "20px",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        marginTop: "20px"
    },
    "& .noIssuesContracts": {
        color: "#a7a7a7",
        fontSize: "16px",
    },
    "& .activeContractsContainer": {
        padding: "20px",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "#fff",
        marginTop: "20px",
        flexDirection: "column"

    },
    "& .activeContractHeadingContainer": {
        display: 'flex',
        justifyContent: "space-between",
        marginBottom: "20px",
        borderRadius: "20px",
        width: "100%",
        alignItems: "center"

    },
    "& .viewDetailsBtn": {
        width: "250px",
        color: "#fff",
        borderRadius: "8px",
        backgroundColor: "#2b6fec",
        padding: "10px 0px",
        fontSize: "17px",
        textTransform: "capitalize"
    },
    "& .activeContractHeading": {
        fontSize: "32px",
        fontWeight: 900,
        color: "#1b1c21"
    },
    "& .activeBuildingContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .activelocationContainer": {
        display: "flex",
        gap: "20px",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    "& .activeContractListContainer": {
        width: "100%"
    },
    "& .activeContractListItem": {
        display: "flex",
        gap: "10px",
        justifyContent: "flex-start",
        margin: "20px 0px"
    },
    "& .activeContractListItemLabel": {
        color: "#272829",
        fontSize: "21px",
        fontWeight: 500
    },
    "& .activeContractListItemTitle": {
        color: "#484b4c",
        fontWeight: 900,
        fontSize: "21px"
    },
    "& .buildingOnMapText": {
        color: "#eb9456",
        fontSize: "19px",
    },
    "& .activeContractFooterContainer": {
        marginTop: "20px"
    },
    "& .activeContractFooterText": {
        fontSize: "18px",
        color: "#a7a7a8"
    },
    "& .activeContractFooterBoldText": {
        fontSize: "18px",
        color: "#8f8f91",
        fontWeight: 900
    },
    "& .issueNewLeaseText": {
        color: "#537ad2",
        cursor: "pointer"
    },
    "& .ownerDetailsContainer":{
        backgroundColor:"#fff",
        borderRadius:"10px",
        marginTop:"20px"
    },
    "& .ownerDetailsHeader":{
        padding:"20px"
    },
    "& .ownerHeading":{
        fontSize:"20px",
        fontWeight:"900"
    },
    "& .ownerDetailsSection":{
        padding:"20px",
        display:"flex",
        justifyContent:"space-between",
    },
    "& .ownerDetailsSection1":{
        display:"flex",
        justifyContent:"flex-start",
        alignItems: "flex-start",
        gap:"10px"
    },
    

});

const IssueNewLeaseModalContainer = styled(Box)({
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .mobile-box": {
        display:" flex",
        height:" 55px",
        alignItems:" center",
        border:" 1px solid #f0f0f0",
        borderRadius:" 8px",
        backgroundColor:" #f9f9f9",
    },
    "& .mobile-input":{
        outline:"none",
        textdecoration:"none",
        width:"100%"
    },
    "& .MuiInput-underline::before":{
        borderBottom:"none !important"
    },
    "& .issueNewModalContainer": {
        backgroundColor: "#fff",
        borderRadius: "10px",
        width: "65%"
    },
    "& .invitationNewModalContainer":{
        backgroundColor: "#fff",
        borderRadius: "10px",
        width: "70%",
        height: "80%",
        overflow: "scroll"
    },
    "& .issueNewLeaseModalHeader": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px"
    },
    "& .issueNewLeaseModalHeading": {
        color: "#1b1f27",
        fontSize: "20px",
        fontWeight: 900,
        cursor: "pointer"
    },
    "& .issueNewModalContentcontainer": {
        padding: "20px",
    },
    "& .issueNewModalContentheading": {
        color: "#949494",
        fontSize: "17px",
        marginBottom: "20px"
    },
    "& .issueNewLeasefieldsContiner": {
        padding: "10px 0px"
    },
    "& .issueNewLeasefieldsLabel": {
        color: "#474747",
        fontSize: "16px",
        fontWeight: 800,
        marginBottom: "8px"
    },
    "& .MuiTextField-root": {
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
        border: "1px solid #efefef"
    },
    "& .MuiFormControl-fullWidth": {
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
        border: "1px solid #efefef"
    },
    "& .issueNewLeaseModalBtnContainer": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "10px 0px",
        width: "100%"
    },
    "& .issueNewLeaseModalBtn": {
        width: "180px",
        border: "1px solid #3e6de6",
        color: "#3e6de6",
        background: "#fff",
        height: "50px"
    },
    "& .searchbtnContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        height: "80px",
    },
    "& .modalSearchBtn": {
        backgroundColor: "#3e6de6",
        color: "#fff",
        height: "53px",
        fontSize: "17px",
        width: "150px"
    },
    "& .issueNewLeasefieldsRow": {
        display: "flex",
        gap: "20px"
    },
    "& .tableEmailRowcontainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .tableContainer": {
        margin: "10px 0px"
    },
    "& .noRecordContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "200px",
        gap: "20px",
        flexDirection: "column"

    },
    "& .noRecordHeading": {
        color: "#474649",
        fontSize: "16px",
        textAlign: "center",
    },
    "& .noRecordBtn": {
        color: "#fff",
        backgroundColor: "#fe8435",
        padding: "10px 20px",
        fontWeight: 900
    },
    "& .addNuwPersonModalFormContainer": {
        margin: "10px 0px",
        padding: "20px"
    },
    "& .findPersonModalBtnContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px"
    },
    "& .findPersonModalBtn": {
        width: "180px",
        border: "1px solid #3e6de6",
        color: "#3e6de6",
        background: "#fff",
        height: "50px",
        fontWeight: 900,
        borderRadius: "10px"
    },
    "& .findPersonModalSendBtn": {
        width: "180px",
        color: "#fff",
        background: "#2c6df3",
        height: "50px",
        fontWeight: 900,
        borderRadius: "10px"
    },
    "& .tableHead": {
        borderBottom: "none",
        color: "#272a2b",
        fontWeight: 800,
    }
})


const OpenFindShowPersonModalContainer = styled(Box)({
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    overflow: "scroll",
    height: "100vh",
    "& .openFindShowPersonModalContainer": {
        width: "70%",
        backgroundColor: "#fff",
        borderRadius: "10px",
    },
    "& .showPersonHeadingContainer": {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
        display: "flex",
    },
    "& .showPersonHeading": {
        fontWeight: 800,
      fontSize: "18px",
    },
    "& .showPersonContentContainer": {
      padding: "20px",
    },
    "& .showPersonFieldLable": {
        marginBottom: "5px",
        fontSize: "16px",
        fontWeight: 800,
    },
    "& .showPersonFieldValue": {
      color: "#a8a8a8",
      fontSize: "16px",
      fontWeight: 400,
    },
    "& .personIdPic": {
        objectFit: "contain",
        width: "100px",
      height: "70px",
    },
    "& .showPersonBtnContainer": {
      display: "flex",
      justifyContent: "flex-end",
      gap: "20px",
      alignItems: "center",
    },
    "& .showPersonBtnOutlined": {
        borderRadius: "6px",
        height: "40px",
        color: "#2b6fec",
        fontWeight: 900,
        backgroundColor: "#fff",
        border: "1px solid #2b6fec",
        width: "180px",
    },
    "& .showPersonBtnContained": {
        color: "#fff",
        height: "40px",
        borderRadius: "6px",
        width: "180px",
      fontWeight: 900,
      backgroundColor: "#2b6fec",
    },
    "& .tenantDetailsCart": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px 0px",
        width: "100%"
    },
  });

  const PopoverContainer = styled(Box)({
    width: "150px",
    "& .popoverText": {
        padding: "10px 20px ",
    },
});
// Customizable Area End