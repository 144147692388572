// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  match?: any;
  location?: any;
  history?: any;
}

export type FileType = string | ArrayBuffer | null;

interface S {
  sid: null | string | undefined;
  buildingId: string | number | null;
  editBuildingId: string;
  userType: string;

  step: number;

  open: boolean;
  multiPleButton: string[];
}

interface SS {
  id: any;
}

export default class CompEmpComplexBuildingBlockController extends CommonApiCallForBlockComponent<Props, S, SS> {
  exampleAPICallId: string = "";
  getUploadDocumentApiCall = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      sid: "",
      buildingId: "",
      editBuildingId: "",
      userType: "",

      step: 0,

      open: false,
      multiPleButton: ["Basic Details", "Documents", "Units", "Shared Facilities", "Core Members"],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    const complex_id = this.props.navigation.getParam("id");
    const building_id = this.props.navigation.getParam("bId");
    const user_type = localStorage.getItem("role") || "";

    this.setState({ sid: complex_id, userType: user_type }, () => {
      if (building_id) {
        this.setState({ buildingId: building_id, editBuildingId: building_id });
      }
    });
  }

  handleNext = () => {
    this.setState({ step: this.state.step + 1 });
  };

  handleBackDoc = () => {
    this.setState({ step: this.state.step - 1 });
  };

  updateEditBuilding = (buildingId: string | number) => {
    this.setState({ buildingId: buildingId }, () => {
      this.handleNext();
    });
  };

  handleNextCore = () => {
    this.setState({ open: !this.state.open });
  };

  handleCloseCodeModal = () => {
    this.setState({ open: false }, () => {
      this.props.navigation.navigate("CEComplexDetails", { id: this.state.sid });
    });
  };

  getColor = (index: number, step: number) => {
    if (step === index) {
      return "#FFFFFF";
    } else if (step < index) {
      return "#83878e";
    } else {
      return "#2B6FED";
    }
  };

  handleSuccessHeading = (t: any) => {
    if (this.state.editBuildingId) {
      return t("New Building Edited");
    } else {
      return t("New Building Created");
    }
  }

  handleSuccessDescription = (t: any) => {
    if (this.state.editBuildingId) {
      if (this.state.userType === "coo") {
        return t("Building edited successfully.");
      } else {
        return t("Approval Request has been sent to the COO. Once the request is approved the building will be edited to the platform.")
      }
    } else {
      if (this.state.userType === "coo") {
        return t("New building created successfully.");
      } else {
        return t("Approval Request has been sent to the COO. Once the request is approved the building will be added to the platform.")
      }
    }
  }
}
// Customizable Area End
