//Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Select,
  TextField,
  Menu,
  MenuItem,
  Button,
  Modal,
  styled,
  IconButton,
  Divider,
  InputAdornment
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/SearchOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import AddSubscriptionController from "./AddSubscriptionController";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import * as Yup from 'yup';
import { numbersOnly } from "../../../components/src/KeyBlockFunctions";
import { CSSProperties } from '@material-ui/styles';
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import {imgDeactivateImg} from "./assets"
import moment from "moment/moment";

class AddSubscription extends AddSubscriptionController {
  
  renderPropertyManager = (t: any) => {
    switch (this.state.initialValues.subscriptionRole) {
      case "property_manager": {
        return (
          <Grid item xs={6} sm={6}>
            <Grid container>
              <Grid item xs={12}>
                <label htmlFor="propertyManager" style={webStyle.label}>{t("Property Manager")}</label>
              </Grid>
              <Grid item xs={12} className="pos_relative">
                <Field
                  as={Select}
                  className={`estate_select`}
                  name="propertyManager"
                  id="propertyManager"
                  data-testid='propertyManager'
                  style={webStyle.formikSelectField}
                  displayEmpty
                  onChange={(e: any) => {
                    this.handleValueChange(e.target.value,"propertyManager");
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Property Manager
                  </MenuItem>
                  {
                    this.state.PropertyManagerList?.map((manager: any, i: number) => {
                      return <MenuItem data-testid='propertyManager' value={manager} className="text_upper" key={i}>{manager.attributes.full_name}</MenuItem>
                    })
                  }
                </Field>
                <Box style={webStyle.errorMsg}>
                  <ErrorMessage name="propertyManager" component="div" />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )
      }
      case "owner": {
        return null
      }
      default: {
        return (
          <Grid item xs={6} sm={6}>
            <Grid container>
              <Grid item xs={12}>
                <label htmlFor="companyFilter" style={webStyle.label}>{t("Company")}</label>
              </Grid>
              <Grid item xs={12} className="pos_relative">
                <Field
                  as={Select}
                  className={`estate_select`}
                  name="companyFilter"
                  id="companyFilter"
                  data-testid="companyFilter"
                  style={webStyle.formikSelectField}
                  displayEmpty
                  value={this.state.initialValues?.companyFilter}
                  onChange={(e: any) => {
                    this.handleValueChange(e.target.value,"companyFilter");
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Company
                  </MenuItem>
                  {
                    this.state.companyList.map((company: any, i: number) => {
                      return <MenuItem data-testid="companyFilter" value={company} className="text_upper" key={i}>{company?.attributes?.company_name}</MenuItem>
                    })
                  }
                </Field>
                <Box style={webStyle.errorMsg}>
                  <ErrorMessage name="companyFilter" component="div" />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    }
  }

  renderComplexBuilding = (t: any, values: any) => {
    switch (this.state.initialValues.subscriptionRole) {
      case "general_assembly": {
        return (
          <>
            <Grid item xs={6} sm={6}>
              <Grid container className="countries_form_real_estate_grid_container">
                <Grid item xs={12} className="countries_form_real_estate_grid_item"><label htmlFor="country" style={webStyle.label} className="countries_form_real_estate_grid_label">
                  {t("Complex/Individual Building")}</label></Grid>
                <Grid item xs={12} className="pos_relative countries_form_real_estate_grid_item_new">
                  <Field
                    as={Select}
                    name="complexBuildingRole"
                    id="complexBuildingRole"
                    className={`estate_select countries_form_real_estate_grid_field`}
                    labelId="demo-simple-select-label"
                    data-testid="individualBuilding"
                    style={webStyle.formikSelectField}
                    value={this.state.initialValues.individualBuilding}
                    displayEmpty
                    onChange={(e:any)=>{
                      this.handleValueChange(e.target.value,"individualBuilding");
                  }}
                  >
                    <MenuItem value={values.complexBuildingRole} disabled>
                      Select Building
                    </MenuItem>
                    {
                      this.state.complexBuildingFilterList.map((sub: { label: string, value: string }, i: number) => {
                        return <MenuItem data-testid="complexBuildingRole-select" value={sub.value} key={i}>{sub.label}</MenuItem>
                      })
                    }
                  </Field>
                  <Box style={webStyle.errorMsg}>
                    <ErrorMessage name="complexBuildingRole" component="div" />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {/* Complex */}
            <Grid item xs={6} sm={6}>
              <Grid container className="countries_form_real_estate_grid_container">
                <Grid item xs={12} className="countries_form_real_estate_grid_item"><label htmlFor="country" style={webStyle.label} className="countries_form_real_estate_grid_label">
                Select  {this.state.complexLabelname}</label></Grid>
                <Grid item xs={12} className="pos_relative countries_form_real_estate_grid_item_new">
                  <Field
                    as={Select}
                    name="complexFilter"
                    id="complexFilter"
                    data-testid="complexFilter"
                    className={`estate_select countries_form_real_estate_grid_field`}
                    labelId="demo-simple-select-label"
                    style={webStyle.formikSelectField}
                    value={this.state.initialValues.complexFilter}
                    displayEmpty
                    onChange={(e:any)=>{
                      this.handleValueChange(e.target.value,"complexFilter");
                  }}
                  >
                    <MenuItem value="" disabled>
                     Select  {this.state.complexLabelname}
                    </MenuItem>
                    {
                      this.state.complexList?.map((complex: any, i: number) => {
                        return <MenuItem data-testid="complexFilter" value={complex} key={i}>{complex.attributes.name}</MenuItem>
                      })
                    }
                  </Field>
                  <Box style={webStyle.errorMsg}>
                    <ErrorMessage name="complexFilter" component="div" />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      }
      case "property_developer": {
        return (
          <>
            <Grid item xs={6} sm={6}>
              <Grid container>
                <Grid item xs={12}>
                  <label htmlFor="totalComplex" style={webStyle.label}>{t("Total Complex")}</label>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    type="text"
                    name="totalComplex"
                    id="totalComplex"
                    placeholder={t("Enter number")}
                    style={{
                      ...webStyle.formikField,
                      backgroundColor: this.state.propertyComplex !== "" ? "#cccccc" : 'rgb(243, 243, 243) none repeat scroll 0% 0%',
                    }}
                    data-testid="totalComplex-input"
                    onKeyDown={numbersOnly}
                    value={this.state.propertyComplex}
                    InputProps={{
                      readOnly: this.state.propertyComplex !== "", 
                    }}
                  />
                  <Box style={webStyle.errorMsg}>
                    <ErrorMessage name="totalComplex" component="div" />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} sm={6}>
              <Grid container>
                <Grid item xs={12}>
                  <label htmlFor="totalBuilding" style={webStyle.label}>{t("Total Individual Building")}</label>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    type="text"
                    name="totalBuilding"
                    id="totalBuilding"
                    placeholder={t("Enter Individual Building")}
                    data-testid="totalBuilding-input"
                    onKeyDown={numbersOnly}
                    value={this.state.propertyBuilding}
                    InputProps={{
                      readOnly: this.state.propertyBuilding !== "", 
                    }}
                     style={{
                      ...webStyle.formikField,
                      backgroundColor: this.state.propertyBuilding !== "" ? "#cccccc" : 'rgb(243, 243, 243) none repeat scroll 0% 0%',
                    }}
                  />
                  <Box style={webStyle.errorMsg}>
                    <ErrorMessage name="totalBuilding" component="div" />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      }
      default: {
        return null
      }
    }
  }

  renderFindPersonModal = (t: any, values: any) => {
    return (
      <Box>
        <Modal open={this.state.ownerListOpen}>
          <ContactDetailsHeader>
            <Box className="modalContainer">
              <Box className="ContractDetailsModal">
                <div className="contractDetailsHeader">
                  <Typography className="ContractDetailsModalHeading" data-testId={"findPersonModalHeadingTestId"}>
                    Find Person
                  </Typography>
                  <IconButton onClick={this.handleClose} className="closeIconButton" data-testId={"findPersonModalCrossIconTestId"}>
                    <CloseIcon  />
                  </IconButton>
                </div>
              </Box>
              <Divider className="dividerLine" />

              <Typography className="ContractDetailsModalHeadingChild">
                Fill one of more of the fields to find the person, if two fields
                are populated, any matching person will be shown.
              </Typography>
              <Box className="ContractDetailsModalTextfield">
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                    ID Number
                  </Typography>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextFieldModal"
                    placeholder="ID Number"
                    data-testid="owner-Id"
                    onChange={(e)=>{this.handleFilterChange("ownerId",e.target.value)}}
                  />
                </Box>
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                    Passport Number
                  </Typography>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextFieldModal"
                    placeholder="Passport Number"
                    data-testid="owner-PassPort-Number"
                    onChange={(e)=>{this.handleFilterChange("ownerPassPortNumber",e.target.value)}}
                  />
                </Box>
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                    Email ID
                  </Typography>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextFieldModal"
                    placeholder="Email ID"
                    data-testid="owner-Email-Id"
                    onChange={(e)=>{this.handleFilterChange("ownerEmailId",e.target.value)}}
                  />
                </Box>
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                    Phone Number
                  </Typography>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextFieldModal"
                    placeholder="+12345667890"
                    data-testid="owner-Phone"
                    onChange={(e)=>{this.handleFilterChange("ownerPhone",e.target.value)}}
                  />
                </Box>
                <Box>
                  <Button variant="contained" data-testid="owner-search" onClick={()=>{this.getOwnerList()}} className="ContractDetailsButton">
                    <SearchIcon /> Search
                  </Button>
                </Box>
              </Box>
              <Box className="modalTableContainer">
              {this.state.ownerList.length ? 
                <table style={{ width: "100%" }} className="tableContainer">
                  <thead>
                    <tr>
                      <th className="tableHeadItem">Name</th>
                      <th className="tableHeadItem">ID Number</th>
                      <th className="tableHeadItem">Phone Number</th>
                      <th className="tableHeadItem">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.ownerList.map((item, index) => (
                      <tr key={index}>
                        <td className="tableDataItem">{item?.attributes?.full_name	}</td>
                        <td className="tableDataItem">{item.id}</td>
                        <td className="tableDataItem">{item?.attributes?.full_phone_number}</td>
                        <td className="tableDataItem">
                          <Box className="tableEmailContainer">
                            <Typography>{item?.attributes?.email}</Typography>
                            <Box>
                              <IconButton
                                onClick={(event) =>{this.handleClick(event,index)}}
                                data-testId={`findShowPersonModalMenuIconTestId${index}`}
                                id="findShowPersonModalMenuIconTestId"
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorReference="anchorPosition"
                                anchorPosition={{ top: this.state.top, left: this.state.left }}
                                open={this.state.openMenuIndex === index}
                                onClose={this.handleClose}
                              >
                                <MenuItem id="anchorPositionClick" onClick={()=>{this.handleViewOwner(item)}}>View Details</MenuItem>
                              </Menu>
                            </Box>
                          </Box>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>: <Box style={webStyle.noRecordStyles}>
                  <Typography variant="body1">
                    {t("No Record Found.")}
                  </Typography>
                </Box>
                }
              </Box>
              <Box className="modalCancelBtnContainer">
                <Button
                  variant="text"
                  className="ContractDetailsButton1"
                  onClick={this.handleClose}
                >
                  <span className="bold-text">Cancel</span>
                </Button>
              </Box>
            </Box>
          </ContactDetailsHeader>
        </Modal>
      </Box>
    );
  };

  viewOwnerModal = (t: any, values: any) => {
    return (
      <Box>
        <Modal 
        open={this.state.viewOwnerDeatils}
        >
          <ContactDetailsHeader>
            <Box className="modalContainer">
              <Box className="ContractDetailsModal">
                <div className="contractDetailsHeader">
                  <Typography className="ContractDetailsModalHeading" data-testId={"findPersonModalHeadingTestId"}>
                    Owner Details
                  </Typography>
                  <IconButton onClick={this.handleClose} className="closeIconButton" data-testId={"findPersonModalCrossIconTestId"}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </Box>
              <Divider className="dividerLine" />
              <Box className="ContractDetailsModalTextfield">
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                   Owner Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextFieldModal"
                    placeholder="ID Number"
                    disabled
                    value={this.state.viewOwnerObj?.attributes?.full_name}
                  />
                </Box>
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                    Complex
                  </Typography>
                  <TextField
                    variant="outlined"
                    disabled
                    className="ContractDetailsTextFieldModal"
                    placeholder="Passport Number"
                    value={this.state.viewOwnerObj?.attributes?.complex?.name}
                  />
                </Box>
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                    Owner ID
                  </Typography>
                  <TextField
                    variant="outlined"
                    disabled
                    className="ContractDetailsTextFieldModal"
                    placeholder="Email ID"
                    value={this.state.viewOwnerObj?.id}
                  />
                </Box>
               
              </Box>
              <Box className="OwnerDetailsModalTextfield">
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                    Email 
                  </Typography>
                  <TextField
                    variant="outlined"
                    disabled
                    className="ContractDetailsTextFieldModal"
                    placeholder="ID Number"
                    value={this.state.viewOwnerObj?.attributes?.email}
                  />
                </Box>
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                    Address
                  </Typography>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextFieldModal"
                    placeholder="Passport Number"
                    value={this.state.viewOwnerObj?.attributes?.address?.address}
                  />
                </Box>
              </Box>
            
              <Box className="modalCancelBtnContainer">
                <Button
                  variant="text"
                  className="ContractDetailsButton1"
                  data-testid="Cancel-button"
                  onClick={()=>{this.handleClose()}}
                >
                 <span className="bold-text">CANCEL</span>
                </Button>
                <Button
                  variant="text"
                  style={webStyle.addBtn}
                  data-testid="add-button"
                  onClick={()=>{this.handleAddOwner(this.state.viewOwnerObj)}}
                >
                  <span className="bold-text">ADD</span>
                </Button>
              </Box>
            </Box>
          </ContactDetailsHeader>
        </Modal>
      </Box>
    );
  };


  openModel = (t: any, values: any) => {
    return (
      <Modal
      open={this.state.openModel}
      style={webStyle.modal}
    >
      <div  style={webStyle.paper}>
        <img src={imgDeactivateImg} alt="checkIcon" width={80} height={76} />
      
        {this.state.accountType!=="coo"?
        <>
          <Typography  style={webStyle.modelLabel} >
          Approval Request Sent
        </Typography>
          <Typography style={webStyle.modelContain} >
            <span>Plan request has been sent to the COO. Once the</span>
            <span>request get approved, plan will be activated
            </span>
          </Typography></>: 
          <>
            <Typography  style={webStyle.modelLabel} >
            Subscription Created Successfully
          </Typography>
           <Typography style={webStyle.modelContain} >
            <span>New subscription has been created sucessfully.</span>
            <span>It will appear to all the users shortly.
            </span>
          </Typography></>}
        <Button color="primary" onClick={this.handleCloseModel} data-testid="closeodel" style={webStyle.okBtn} variant="contained">
          OKAY
        </Button>
      </div>
    </Modal>
    )
  }

  render() {
    const { t }: any = this.props;
    const maxDate = "5000-05-31";
    const validationSchema = Yup.object().shape({
    });
    return (
      <>
        <MainScreen>
          <Box maxWidth='xl' style={webStyle.registerContainer} >
            <Box>
              <Typography variant="body1">
                <Link
                  to={"/CompanyEmployee/SubsciptionListing"}
                  color="inherit"
                >
                  {t("Manage Subscriptions")}
                </Link>{" "}
                /
                <Link to={""} color="inherit">
                  <Box component="span" style={{ color: "#2c6fed" }}>
                    {t("New Subscription")}
                  </Box>
                </Link>
              </Typography>
            </Box>
            <Box style={{ marginTop: '24px' }}>
              <Typography
                variant="h5"
                className="bold-text"
                style={webStyle.subHeading}
              >
                {t("Create New Subscription")}
              </Typography>
            </Box>
            <Box >

              <Formik
                initialValues={this.state.initialValues}
                validationSchema={validationSchema}
                onSubmit={this.handleSubmit}
                enableReinitialize={true}
              >
                {/* @ts-ignore */}
                {({ values, isSubmitting, formikProps, handleChange }) => (
                  <Form translate={undefined}>
                    <Box className=" field_placeholder" style={webStyle.formContainer} >
                      <Box style={{ marginBottom: "20px" }}>
                        <Typography component="h3" className="bold-text" style={{ fontSize: "22px" }}>
                          {t("Basic Details")}
                        </Typography>
                      </Box>
                      <Grid container spacing={2}>
                        {/* Subscription For */}
                        <Grid item xs={6} sm={6}>
                          <Grid container className="countries_form_real_estate_grid_container">
                            <Grid item xs={12} className="countries_form_real_estate_grid_item"><label htmlFor="country" style={webStyle.label} className="countries_form_real_estate_grid_label">
                              {t("Subscription For")}</label></Grid>
                            <Grid item xs={12} className="pos_relative countries_form_real_estate_grid_item_new">
                              <Field as={Select} name="subscriptionRole"
                                id="subscriptionRole"
                                className={`estate_select countries_form_real_estate_grid_field`}
                                labelId="demo-simple-select-label"
                                data-testid="subscriptionRole"
                                style={webStyle.formikSelectField}
                                displayEmpty
                                onChange={(e: any) => {
                                  
                                  this.handleSubscriptionRoleChange(e.target.value);
                                }}
                              >
                                <MenuItem value={values.subscriptionRole} disabled>
                                  {"Subscription For"}
                                </MenuItem>
                                {
                                  this.state.subscriptionFilterList.map((sub: { label: string, value: string }, i: number) => {
                                    return <MenuItem data-testid="subscriptionRole" value={sub.value} key={i}>{sub.label}</MenuItem>
                                  })
                                }
                              </Field>
                              <Box style={webStyle.errorMsg}>
                                <ErrorMessage name="subscriptionRole" component="div" />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* Country */}
                        {this.state.accountType!=="account_manager"&&
                        <Grid item xs={6} sm={6}>
                          <Grid container className="countries_form_real_estate_grid_container">
                            <Grid
                              item
                              xs={12}
                              className="countries_form_real_estate_grid_item"
                            >
                              <label htmlFor="country" style={webStyle.label} className="countries_form_real_estate_grid_label">
                                {t("Country")}</label></Grid>
                            <Grid
                              item
                              xs={12}
                              className="pos_relative countries_form_real_estate_grid_item_new"
                            >
                              <Field
                                as={Select}
                                name="countryFilter"
                                id="countryFilter"
                                className={`estate_select countries_form_real_estate_grid_field`}
                                labelId="demo-simple-select-label"
                                data-testid="countryFilter"
                                style={webStyle.formikSelectField}
                                displayEmpty
                                value={values.countryFilter}
                                onChange={(e: any) => {
                                 
                                  this.handleCountryChange(e.target.value);
                                }}
                              >
                               
                                <MenuItem value="" disabled>
                                  Select Country
                                </MenuItem>
                                {
                                  this.state.countryList.map((country: any, i: number) => {
                                    return <MenuItem data-testid="countryFilter" value={country?.value} key={i}>{country?.label}</MenuItem>
                                  })
                                }
                              </Field>
                              <Box style={webStyle.errorMsg}>
                                <ErrorMessage name="countryFilter" component="div" />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        }

                        {/* City */}
                        {this.state.accountType!=="account_manager"&&
                        <Grid item xs={6} sm={6}>
                          <Grid container>
                            <Grid item xs={12}>
                              <label htmlFor="cityFilter" style={webStyle.label}>{t("City")}</label>
                            </Grid>
                            <Grid item xs={12} className="pos_relative">
                              <Field
                                as={Select}
                                className={`estate_select text_capital`}
                                name="cityFilter"
                                id="cityFilter"
                                data-testid="cityFilter"
                                style={webStyle.formikSelectField}
                                displayEmpty
                                value={values.cityFilter}
                                onChange={(e: any) => {
                                 
                                  this.handleCityChange(e.target.value);
                                }}
                              >
                                <MenuItem value="" disabled>
                                  Select City
                                </MenuItem>
                                {
                                  this.state.cityList?.map((city: any, i: number) => {
                                    return <MenuItem data-testid="cityFilter" value={city.value} className="text_capital" key={i}>{city.label}</MenuItem>
                                  })
                                }
                              </Field>
                              <Box style={webStyle.errorMsg}>
                                <ErrorMessage name="city" component="div" />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        }
                        {this.renderPropertyManager(t)}
                        {this.renderComplexBuilding(t, values)}
                        {this.renderFindPersonModal(t,values)}
                        {this.viewOwnerModal(t,values)}
                        <Grid item xs={6} sm={6}>
                          <Grid container>
                            <Grid item xs={12}>
                              <label htmlFor="planFilter" style={webStyle.label}>{t("Select Plan")}</label>
                            </Grid>
                            <Grid item xs={12} className="pos_relative">
                              <Field
                                as={Select}
                                className={`estate_select text_capital`}
                                name="planFilter"
                                id="planFilter"
                                data-testid="planFilter"
                                style={webStyle.formikSelectField}
                                onChange={(e: any) => {
                                  this.handleValueChange(e.target.value,"planFilter");
                                }}
                                displayEmpty>
                                <MenuItem value="" disabled>
                                  Select Plan
                                </MenuItem>
                                {
                                  this.state.planList.map((plan: any, i: number) => {
                                    return <MenuItem value={plan.id} className="text_capital" key={i}>{plan.attributes.title}</MenuItem>
                                  })
                                }
                              </Field>
                              <Box style={webStyle.errorMsg}>
                                <ErrorMessage
                                  name="planFilter"
                                  component="div" />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <Grid container>
                            <Grid item xs={12}>
                              <label htmlFor="totalUnits" style={webStyle.label}>
                                {t("Total Units")}</label>
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                as={TextField}
                                type="text"
                                name="totalUnits"
                                id="totalUnits"
                                placeholder={t("Enter Number")}
                                data-testid="totalUnits-input"
                                value={this.state.totalUnits}
                                InputProps={{
                                  readOnly: this.state.totalUnits !== "", // Set readOnly when value is present
                                }}
                                 style={{
                                  ...webStyle.formikField,
                                  backgroundColor: this.state.totalUnits !== "" ? "#cccccc" : 'rgb(243, 243, 243) none repeat scroll 0% 0%',
                                }}
                              />
                              <Box style={webStyle.errorMsg}>
                                <ErrorMessage
                                  name="totalUnits"
                                  component="div"

                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>

                        {
                          this.state.initialValues.subscriptionRole === "property_developer" ? null :
                            <Grid item xs={6} sm={6}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <label htmlFor="startDateFilter" style={webStyle.label}>
                                    {t("Select Start Date")}</label>
                                </Grid>
                                <Grid item xs={12}>
                                  <div style={webStyle.datePickerContainer}>
                                    <TextField
                                      variant="outlined"
                                      className="startDateCENomination"
                                      placeholder={t("Start Date")}
                                      type="date"
                                      fullWidth
                                      id="nominationDate"
                                      name="startDate"
                                      onChange={this.handleDateChange}
                                      inputProps={{
                                        min: moment().format("YYYY-MM-DD"),
                                        max: maxDate
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <DateRangeOutlinedIcon style={{ color: "#7a7878" }} />
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                  </div>
                                  <Box style={webStyle.errorMsg}>
                                    <ErrorMessage
                                      name="startDateFilter"
                                      component="div"

                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                        }

                          {
                          this.state.initialValues.subscriptionRole === "owner" &&
                            <Grid item xs={6} sm={6}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <label htmlFor="startDateFilter" style={webStyle.label}>
                                    {t("Owner Name")}</label>
                                </Grid>
                                <Grid item xs={12}>
                                  <Field
                                    as={TextField}
                                    type="text"
                                    name="name"
                                    id="ownerName-input"
                                    placeholder={t("+ Add Owner")}
                                    value={this.state.selectedOwner?.attributes?.full_name}
                                    style={webStyle.formikFieldOwner}
                                    onClick={()=>{this.handleOpen()}}
                                    data-testid="ownerName-input"
                                  />
                                  <Box style={webStyle.errorMsg}>
                                    <ErrorMessage
                                      name="startDateFilter"
                                      component="div"

                                    />
                                  </Box>
                                
                                </Grid>
                              </Grid>
                            </Grid>
                        }
                        <Grid item xs={6} sm={6}>
                          <Grid container>
                            <Grid item xs={12}>
                              <label htmlFor="durationFilter" style={webStyle.label}>{t("Duration (Days)")}</label>
                            </Grid>
                            <Grid item xs={12} className="pos_relative">
                              <Field
                                as={Select}
                                className={`estate_select text_capital`}
                                name="durationFilter"
                                data-testid="durationFilter"
                                id="durationFilter"
                                style={webStyle.formikSelectField}
                                displayEmpty
                                onChange={(e: any) => {
                                  this.handleValueChange(e.target.value,"durationFilter");
                                }}
                              >
                                <MenuItem value="" disabled>
                                  Select Days
                                </MenuItem>
                                {Array.from({ length: 365 }, (_, i) => i + 1).map((day, i) => (
                                  <MenuItem value={day} className="text_capital" key={i}>
                                    {`${day} Days`}
                                  </MenuItem>
                                ))}
                              </Field>
                              <Box style={webStyle.errorMsg}>
                                <ErrorMessage
                                  name="durationFilter"
                                  component="div"
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                          {this.state.accountType!=="account_manager"&&this.state.initialValues.planFilter!== "2"&&
                        <Grid item xs={6} sm={6}>
                          <Grid container>
                            <Grid item xs={12}>
                              <label htmlFor="referenceNumber" style={webStyle.label}>
                                {t("Payment Reference Number")}</label>
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                as={TextField}
                                type="text"
                                name="referenceNumber"
                                id="referenceNumber"
                                placeholder={t("Enter Number")}
                                style={webStyle.formikField}
                                data-testid="referenceNumber-input"
                                onChange={(e: any) => {
                                  this.handleValueChange(e.target.value,"referenceNumber");
                                }}
                              />
                              <Box style={webStyle.errorMsg}>
                                <ErrorMessage name="referenceNumber" component="div" />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                          }
                      </Grid>
                    </Box>
                    <Box style={webStyle.errorContainer}>
                      {this.state.errorMsg && (
                        <Typography style={{ color: 'red', fontSize: '14px' }}>
                          {this.state.errorMsg}
                        </Typography>
                      )}
                    </Box>
                    <Box style={webStyle.btnContainer}>
                      <Button type="submit" data-testid="submit-button" style={webStyle.submitBtn}>
                        <span className="bold-text" style={webStyle.btnText}>{t("CREATE")}</span>
                      </Button>
                    </Box>
                  </Form>
                )}
              
              </Formik>
              {this.openModel(1,3)}
            </Box>
          </Box>
        </MainScreen>
      </>
    );
  }
}
  

const ContactDetailsHeader = styled(Box)({
  height: "100vh",
  border: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "scroll",

  "& .modalContainer": {
    backgroundColor: "white",
    width: "70%",
    borderRadius:"8px",
    height:"100%"
  },

  "& .ContractDetailsModal": {
    display: "flex",
    flexDirection: "column", // Add this to stack items vertically
  },
  "& .contractDetailsHeader": {
    display: "flex",
    justifyContent: "space-between", // Align items to the start and end
    padding: "20px",
  },
  "& .ContractDetailsModalHeading": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontWeight: "bold",
    marginRight: "auto", // Push the heading to the left
  },
  "& .closeIconButton": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  "& .ContractDetailsModalHeadingChild": {
    color: "#c2c2c2",
    padding: 15,
    fontSize: 14,
  },
  "& .ContractDetailsTextFieldHeading": {
    fontSize: 14,
  },
  "& .OwnerDetailsLocalID":{
    marginRight:"50px"
  },
  "& .OwnerDetailsLocalIDImage":{
    borderRadius:"8px",
    marginTop:"20px"
  },
  "& .ContractDetailsModalTextfield": {
    display: "flex",
    justifyContent: "space-between",
    padding: 20,
  },
  "& .OwnerDetailsModalTextfield": {
    display: "flex",
    justifyContent: "flex-start",
    padding: 20,
    gap:"5%"
  },
  "& .ContractDetailsButton": {
    background: "#3274ce",
    color: "white",
    fontWeight: 800,
    height: 55,
    marginTop: 21,
    borderRadius: "10px",
  },
  "& .ContractDetailsTextFieldModal": {
    border: "1px solid #f1f1f1",
    borderRadius: "10px",
    background: "#f9f9f9",
    "& .MuiTextField-root": {
      borderRadius: "10px",
      background: "#f9f9f9",
      border: "1px solid #f1f1f1"
    }
  },
  "& .modalCancelBtnContainer": {
    padding: "20px",
    display: "flex",
    gap:"20px",
    justifyContent: "flex-end",
    backgroundColor:"white"
  },
  "& .ContractDetailsButton1": {
    color: "#3274ce",
    fontWeight: 800,
    border: "1px solid #3274ce",
    paddingRight: 40,
    paddingLeft: 40,
    borderRadius: '8px',
    cursor: "pointer",
  },
  "& .modalTableContainer": {
    padding: "20px",
    backgroundColor:"white"
  },
  "& .tableHeadItem": {
    fontSize: "16px",
    fontWeight: 900,
    textAlign: "left",
    borderBottom:"none",
    color:"#272a2b"
  },
  "& .tableEmailContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .tableDataItem": {
    textAlign: "left",
    borderBottom: "1px solid #f1f1f1",
  },
  "& .tableContainer": {
    borderCollapse: "collapse",
  },
});
const webStyle: any = {
  subHeading: {
    // fontWeight: 600,
    marginTop: 15,
    fontSize: "32px"
  },
  formikSelectField: {
    width: 'calc(100% - 20px)',
    background: 'rgb(243, 243, 243) none repeat scroll 0% 0%',
    border: '1px solid rgb(227, 227, 227)',
    padding: '5px',
    borderRadius: '8px',
    marginTop: '10px',
  },
  formikField: {
    width: 'calc(100% - 32px)',
    background: 'rgb(243, 243, 243) none repeat scroll 0% 0%',
    border: '1px solid rgb(227, 227, 227)',
    padding: '5px',
    borderRadius: '8px',
    marginTop: '10px',
  }, 
  formikFieldOwner: {
    width: 'calc(100% - 32px)',
    background: 'rgb(243, 243, 243) none repeat scroll 0% 0%',
    border: '1px solid rgb(252, 132, 52)',
    padding: '5px',
    borderRadius: '8px',
    marginTop: '10px',
  },
  registerContainer: {
    padding: '30px'
  },
  formContainer: {
    background: '#fff',
    padding: "15px",
    marginTop: '30px',
    boxShadow: '4px 0px 14px 0px #E9E9E9',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  errorContainer:{
    display: 'flex', // Use Flexbox
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', 
    marginTop: '20px',
  },
  btnContainer: {
    textAlign: 'right' as CSSProperties['textAlign'],
    marginTop: '30px',
  },
  submitBtn: {
    background: '#2B6FED',
    borderColor: '#2B6FED',
    color: '#fff',
    width: '250px',
    height: '50px',
    boxShadow: '0px 6px 14px rgba(43,111,237,0.22)',
    borderRadius: '8px'
  },
  okBtn: {
    background: '#2B6FED',
    borderColor: '#2B6FED',
    color: '#fff',
    width: '220px',
    height: '50px',
    boxShadow: '0px 6px 14px rgba(43,111,237,0.22)',
    borderRadius: '8px',
    marginTop: '20px',
    fontWeight: 600,

  },
  localIdStyle:{
    marginTop:10,
  },
  addBtn: {
    background: '#2B6FED',
    borderColor: '#2B6FED',
    color: '#fff',
    width: '150px',
    height: '50px',
    boxShadow: '0px 6px 14px rgba(43,111,237,0.22)',
    borderRadius: '8px'
  },
  btnText: {
    fontSize: '16px'
  },
  datePickerContainer: {
    borderRadius: '8px',
    width: 'calc(100% - 32px)',
    height: '43px',
    background: 'rgb(243, 243, 243) none repeat scroll 0% 0%',
    border: '1px solid rgb(227, 227, 227)',
    padding: '5px',
    marginTop: '10px',
    display: 'flex', // Use Flexbox
    justifyContent: 'space-between', // Center horizontally
    alignItems: 'center', 
  },
  modelContain:{
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  modelLabel:{
    fontSize: '22px',
    fontWeight: 1000
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper:{
    padding: 20,
    marginTop:"20px",
    backgroundColor:"#FFFFFF",
    borderRadius:"8px",
    outline: 'none',
    width: 500,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  noRecordStyles: {
    padding: '25px',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
  }
};

export default withTranslation()(withRouter(AddSubscription));

//Customizable Area End
